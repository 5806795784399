import React, { useEffect, useState } from "react"

import SEO from "../components/seo"
import BaseLayout from "../components/layout/baseLayout"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Industry from "../components/companies/Industry"
import BackgroundSquare from "../components/common/BackgroundSquare"

const CompaniesPage = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  )

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        windowWidth !== (typeof window !== "undefined" ? window.innerWidth : 0)
      )
        setWindowWidth(typeof window !== "undefined" ? window.innerWidth : 0)
    }, 500)

    return () => clearInterval(timer)
  }, [])

  return (
    <>
      <SEO title={t('Companies')} isHome={false} />
      <BaseLayout fixedHeader>
        <div className='py-24'>
          <Industry industry='General' id='general'/>
          <Industry industry='Manufacturing' className='bg-slate-50' id='manufacturing'/>
          <Industry industry='Financials' id='financials'/>
          <Industry industry='Retail' className='bg-slate-50' id='retail'/>
          <Industry industry='Utilities' id='utilities'/>
          <Industry industry='Services' className='bg-slate-50' id='services'/>
        </div>
      </BaseLayout>
    </>
  )
}

export default CompaniesPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`